<template>
  <div class="database_background">
    <div class="database_pannel">
      <el-row type="flex" :gutter="5" class="database_info_pannel"
        ><el-col class="database_info_box database_info_box_left" :span="4" v-if="isLogin">
          <el-menu :default-active="$route.path" router>
            <el-menu-item index="/moleculeInfoSelect">
              <template slot="title">
                <i class="el-icon-search"></i>
                <span>view molecular data</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/myMoleculeInfoSelect">
              <template slot="title">
                <i class="el-icon-edit"></i>
                <span>my molecular data</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/propertieRecordEditRecordSelect">
              <template slot="title">
                <i class="el-icon-tickets"></i>
                <span>molecular property edit record</span>
              </template>
            </el-menu-item>
          </el-menu> </el-col
        ><el-col :span="isLogin?20:24"
          ><div class="database_info_box database_info_box_center">
            <router-view /></div></el-col
      ></el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //是否登录
      isLogin:false,
      //查询条件
      condition: {
        name: "",
        molecular_formula: "",
        cas_number: "",
        pub_chem_cid: "",
        content: "",
      },
    };
  },
  mounted(){
    this.getInfo()
  },
  methods: {
    //获取用户信息判断用户是否登录
    getInfo(){
      if (this.$store.state.tokenObj) {
        this.isLogin = true
      }
    }
  },
};
</script>

<style scoped>
.database_background {
  overflow-x: hidden;
}
.database_pannel {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.69);
}
.database_info_pannel {
  padding: 10vh 10vh 0vh 10vh;
}
.el-card {
  border: 0px;
}
.el-menu {
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-item {
  margin: 1rem 0rem;
  color: #fff;
}

.el-menu-item.is-active {
  color: #ffd04b;
}


.database_info_box_left .el-menu {
  margin-top: 1vh;
  margin-right: 1vh;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.database_info_box_center {
  min-height: 80vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
}
</style>